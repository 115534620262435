import type { LoaderFunctionArgs } from '@remix-run/node';
import { json, redirect } from '@remix-run/node';
import piggyLogo from '~/icons/piggy-logo.svg';
import styles from './styles.module.css';

export const loader = ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const shop = url.searchParams.get('shop');

    if (shop) {
        throw redirect(`/app?${url.searchParams.toString()}`);
    }

    return json(null);
};

export default function App() {
    return (
        <div className={styles.index}>
            <div className={styles.content}>
                <h1>Piggy: Loyalty & Rewards</h1>
                <a href="https://leat.com" target="_blank" rel="noreferrer">
                    <img src={piggyLogo} alt="Leat logo" color="#fc5200" />
                </a>
                <p className={styles.description}>
                    Get started with Piggy to create a loyalty program that rewards your customers for shopping with you.
                </p>

                <a href="https://apps.shopify.com/piggy-loyalty-rewards" className={styles.getStartedButton}>
                    Get started
                </a>
            </div>
        </div>
    );
}
